<template>
  <div class="ourHistory">
    <navBar :navType="3"></navBar>

<!--    <div class="pagesText">-->
<!--      <div class="pagesTitle">The official perfume supplier of the royal courts</div>-->
<!--    </div>-->
    <div class="introduce">
      <div style="width:100%;height: 97vh;overflow: hidden;">
        <img class="introduceImg" src="@/assets/bg2.png" alt="">
      </div>
    </div>

    <div class="ourHistoryText">
      <div class="ourHistoryTextOne">Our History</div>
      <div class="ourHistoryTextTow">
        In the realm of personal care, Dr. West’s emerges as a paragon of luxury and purity. With a rich heritage that traces back to the meticulous practices of apothecaries, Dr. West’s has evolved into a symbol of sophistication and excellence in the world of dental and skincare essentials. Our products, ranging from the invigorating toothpaste to the rejuvenating shaving creams and the transformative cosmetics, are crafted with an unwavering dedication to quality and an artisan’s attention to detail.
      </div>
      <div class="ourHistoryTextTow">
        Dr. West`s is more than just a brand; it’s a lifestyle. We are committed to providing our customers with products that are not only effective but also ethically sourced and environmentally friendly. Our packaging is designed to be as beautiful as it is sustainable, reflecting our dedication to the planet.
      </div>
    </div>

    <footerModal></footerModal>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import footerModal from "@/components/footer";

export default {
  name: "ourHistory",
  components: {navBar, footerModal}
}
</script>

<style scoped>
.ourHistoryText{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2vw 3vw;
}
@media screen and (min-width: 768px), screen and (max-width: calc(2119px)) and (orientation: landscape) {
  .ourHistoryTextOne{
    font-size: calc((2.5 - 1) * 1.2vw + 1rem);
    line-height: 1.2;
    font-family: bodyFont;
  }
  .ourHistoryTextTow{
    text-align: left;
    font-size: calc((1.1 - 1) * 1.2vw + 1rem);
    line-height: 1.5;
    font-weight: 400;
    margin: 1vw 0;
    /*font-family: 'navTitle';*/
  }
}

@media screen and (max-width: 768px) {

}

</style>