<template>
  <div class="navBarBox">
    <div class="navBar">
      <img class="logo" src="@/assets/logo.png" @click="goPage('/')" alt="">
      <div class="nav pc">
        <div class="navItem navTitle" :class="navType == 1 ? 'navItemActive' : ''" @click="goPage('/')">The Brand</div>
        <div class="navItem navTitle navItemCenter" :class="navType == 2 ? 'navItemActive' : ''" @click="goPage('/products-and-services')">Products and services</div>
        <div class="navItem navTitle" :class="navType == 3 ? 'navItemActive' : ''" @click="goPage('/our-history')">Our History</div>
        <div class="navItem navTitle navShop" @click="goPage('/cart')">
          <div><svg t="1718256998373" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1763" width="30" height="30"><path d="M921.497421 205.005158h-158.680251a255.935888 255.935888 0 0 0-501.63434 0H102.502579L51.315401 1024h921.369198zM358.438467 409.753869H256.064112V307.379513h102.374355z m9.213692-204.748711a153.561533 153.561533 0 0 1 288.18381 0zM767.935888 409.753869h-102.374355V307.379513h102.374355z" fill="#2c2c2c" p-id="1764"></path></svg>
          </div><div> 0</div>
        </div>
        <div class="contact" @click="goPage('/contact')">Contact</div>
      </div>
      <div class="H5NavBar">
        <div class="navItem navTitle navShop" @click="goPage('/cart')">
          <div><svg t="1718256998373" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1763" width="30" height="30"><path d="M921.497421 205.005158h-158.680251a255.935888 255.935888 0 0 0-501.63434 0H102.502579L51.315401 1024h921.369198zM358.438467 409.753869H256.064112V307.379513h102.374355z m9.213692-204.748711a153.561533 153.561533 0 0 1 288.18381 0zM767.935888 409.753869h-102.374355V307.379513h102.374355z" fill="#2c2c2c" p-id="1764"></path></svg>
          </div><div> 0</div>
        </div>
        <div class="h5Nav" :class="isClick ? 'burger--active' : ''" @click="settingNav">
          <div class="top-bun"></div>
          <div class="bottom-bun"></div>
        </div>
      </div>

    </div>
    <div class="navList" :class="isClick ? 'show' : ''">
      <div class="navListItemBox">
        <div class="navListItem" :class="navType == 1 ? 'navItemActive' : ''" @click="goPage('/')">The Brand</div>
        <div class="navListItem" :class="navType == 2 ? 'navItemActive' : ''" @click="goPage('/products-and-services')">Products and services</div>
        <div class="navListItem" :class="navType == 3 ? 'navItemActive' : ''" @click="goPage('/our-history')">Our History</div>
      </div>
      <div class="contactBut" :class="isClick ? 'butShow' : ''" @click="goPage('/contact')">Contact</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "navBar",
  props: {
    navType: {
      type: Number,
      default() {
        return 0
      }
    }
  },
  data() {
    return {
      isClick: false
    }
  },
  methods: {
    settingNav() {
      this.isClick = !this.isClick
      if (this.isClick) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    },
    goPage(url) {
      console.log(url, this.navType)
      this.$router.push(url)
      this.isClick = false
    }
  },
  beforeDestroy() {
    document.body.style.overflow = '';
  }
}
</script>

<style scoped>

.nav{
  display: flex;
  align-items: center;
}
.navItem{
  display: flex;
  align-items: center;
  font-family: "navTitle";
  cursor: pointer;
}
.navItemCenter{
  margin: 0 1.3rem;
}
.navShop{
  margin: 0 1.3rem;
}
.navItemActive{
  position: relative;
}
.navItemActive:before{
  position: absolute;
  bottom: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  background: #28266C;
}
.contact{
  color: #fff;
  padding: 1rem 1.5rem;
  border-radius: 0.3rem;
  background: #28266C;
  cursor: pointer;
}
.h5Nav, .navList, .H5NavBar {
  display: none;
}
@media screen and (max-width: 768px) {
  .H5NavBar{
    display: flex;
    align-items: center;
  }
  .h5Nav{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 8vw;
    height: 5vw;
  }
  .top-bun, .bottom-bun {
    background: #000;
    height: 1px;
  }
  .top-bun{
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    content: '';
    width: 100%;
    transition: transform 250ms cubic-bezier(.2,.6,.3,1), width 250ms cubic-bezier(.2,.6,.3,1);
    will-change: transform, width;
    transform: translatey(-5.5px);
  }
  .bottom-bun{
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    content: '';
    width: 100%;
    transition: transform 250ms cubic-bezier(.2,.6,.3,1), width 250ms cubic-bezier(.2,.6,.3,1);
    will-change: transform, width;
    transform: translatey(5.5px);
  }
  .burger--active .top-bun {
    transform: translatex(3.5px) rotate(-135deg);
    width: 28px;
  }
  .burger--active .bottom-bun {
    transform: translatex(3.5px) rotate(135deg);
    width: 28px;
  }

  .navList{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 90;
    width: 100%;
    height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    opacity: 0;
    transition: all 0.5s;
  }
  .navList.show {
    opacity: 1;
    /* 刚好显示 12 个题目 */
    height: 100vh;
    z-index: 90;
  }
  .navListItemBox{
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .navListItem{
    font-size: 1.5rem;
    line-height: 2;
    font-family: navTitle;
  }
  .contactBut{
    position: fixed;
    bottom: -4vw;
    color: #fff;
    padding: 1rem 1.5rem;
    border-radius: 0.3rem;
    background: #28266C;
  }
  .butShow {
    transition: all 0.4s;
    bottom: 4vw;
  }
}
</style>