<template>
  <div>
    <navBar></navBar>
    <div class="cartContent">
      <div class="cartContentTitle">Shopping Cart</div>
      <div class="cartContentText">You have nothing in your shopping cart.</div>
      <div class="cartContentBut" @click="goHome">Continue Shopping</div>
    </div>
    <footerModal :styleObj="styleObj"></footerModal>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import footerModal from "@/components/footer";

export default {
  name: "cartPage",
  components: {navBar, footerModal},
  data() {
    return {
      styleObj: {
        position: 'absolute',
        bottom: 0
      }
    }
  },
  methods: {
    goHome() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>

</style>