<template>
  <div class="contactPage">
    <navBar></navBar>
    <div class="content">
      <img class="contentImg" src="@/assets/bg3.png" alt="">
      <div class="contentTextBox">
        <div class="contentTexTitle">Contact us.</div>
        <div class="contentText">info@dr-wests.com</div>
        <div class="contentText">United States</div>
      </div>
    </div>
    <footerModal></footerModal>
  </div>
</template>

<script>
import navBar from "@/components/navBar";
import footerModal from "@/components/footer";

export default {
  name: "contactPage",
  components: {navBar, footerModal}
}
</script>

<style scoped>
@media screen and (min-width: 768px), screen and (max-width: calc(2119px)) and (orientation: landscape) {
  .content{
    display: flex;
    align-items: flex-start;
    padding: 3vw;
    margin-top: 3vw;
  }
  .contentImg{
    width: 48%;
    height: 60vh;
    object-fit: cover;
  }
  .contentTextBox{
    flex: 1;
    margin-top: 4vw;
    text-align: left;
    margin-left: 10vw;
  }
  .contentTexTitle{
    font-size: calc((3 - 1) * 1.2vw + 1rem);
    line-height: 1.2;
    font-family: bodyFont;
  }
  .contentText{
    font-size: calc(1rem);
    line-height: 1.2;
    font-family: 'navTitle';
    margin-top: 3vw;
    font-weight: 400;
  }
}

@media screen and (max-width: 768px) {
  .content{
    display: flex;
    /*align-items: center;*/
    flex-direction: column;
    padding: 3vw;
    margin-top: 15vw;
    margin-bottom: 28vw;
  }
  .contentImg{
    width: 100%;

  }
  .contentTextBox{
    flex: 1;
    margin-top: 8vw;
    text-align: left;
    /*margin-left: 10vw;*/
  }
  .contentTexTitle{
    font-size: calc((5 - 1) * 1.2vw + 1rem);
    line-height: 1.2;
    font-family: bodyFont;
  }
  .contentText{
    font-size: calc(1rem);
    line-height: 1.2;
    /*font-family: 'navTitle';*/
    margin-top: 8vw;
    font-weight: 400;
  }
}

</style>