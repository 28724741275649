import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/pages/home'
import Services from '@/pages/services'
import ourHistory from '@/pages/ourHistory'
import Contact from '@/pages/contact'
import Cart from '@/pages/cart'
Vue.use(Router)


const VueRouterPush = Router.prototype.push

Router.prototype.push = function push (to) {

    return VueRouterPush.call(this, to).catch(err => err)

}

const  router =new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                title: '测试'
            }
        },
        {
            path: '/products-and-services',
            name: 'services',
            component: Services,
            meta: {
                title: '测试'
            }
        },
        {
            path: '/our-history',
            name: 'our-history',
            component: ourHistory,
            meta: {
                title: '测试'
            }
        },
        {
            path: '/contact',
            name: 'contact',
            component: Contact,
            meta: {
                title: '测试'
            }
        },
        {
            path: '/cart',
            name: 'cart',
            component: Cart,
            meta: {
                title: '测试'
            }
        }
    ]
})



export default router

router.beforeEach((to, from, next) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;

    // 兼容IE
    window.scrollTo(0, 0);
    next();
})