<template>
  <div class="homePage">
    <navBar :navType="1"></navBar>
    <div class="topImageBox">
      <img class="pageImg" src="@/assets/banner.png" alt="">
      <div class="topImageBoxDiscover" @click="goPage('/products-and-services')">Discover</div>
    </div>
    <div class="contentBruno">
      <h4 class="contentBrunoTitle">Dr. West`s</h4>
      <h5 class="contentBrunoText">
        Welcome to Dr. West`s, the quintessence of personal care and beauty. Our online emporium offers an exquisite selection of products that cater to the discerning tastes of those who seek nothing but the finest for their grooming and beauty needs. <br/>At Dr. West’s, we believe that true beauty and wellness stem from the harmony of nature and science. Each creation is an ode to the discerning consumer who seeks not just a product, but an experience that transcends the ordinary. Our commitment to sustainability and ethical sourcing further cements our status as a brand not just for the present, but for the ages. Dr. West’s is not just a choice; it is a statement of elegance and a testament to the enduring pursuit of perfection
        <strong><em>.</em></strong>
      </h5>
    </div>

    <div class="boutique">
      <img class="boutiqueImg" src="@/assets/bg3.png" alt="">
      <div class="boutiqueInfo">
        <div class="boutiqueTitle">Green Tea Toothpaste</div>
        <p class="textP">
          At Dr. West`s, we believe that self-care is not just a routine but a form of art. Our meticulously curated collection of toothpaste, cosmetics, and shaving creams are crafted for the modern individual who values quality and sophistication in every aspect of life.
          Our toothpaste range is infused with nature’s best ingredients, promising not only a radiant smile but also a healthy oral ecosystem. Each variant is a blend of science and nature, designed to deliver freshness, protection, and a whiter smile.
        </p>
        <div class="discover" @click="goPage('/products-and-services')">Discover</div>
      </div>
    </div>


    <div class="introduce">
      <div class="introduceBox" style="width:100%;overflow: hidden;position: relative">
        <img class="introduceImg" src="@/assets/bg1.jpg" alt="">
        <div class="introduceText">
          Tortola's allure harmonizes perfectly with the ethos of Dr. West's, where the essence of nature meets the pinnacle of personal care. Just as Tortola is a treasure trove of natural beauty and tranquility, Dr. West's is a bastion of purity and luxury in cosmetics and dental care. The island's verdant hills and clear waters reflect our commitment to using only the finest natural ingredients, mirroring the untouched beauty of Tortola. Our products are like Tortola's gentle breezes and warm sands—soothing, refreshing, and invigorating to the senses.
        </div>
      </div>
    </div>

    <div class="email">
      <p class="emailTitle">
        Stay updated with the latest creations and unique offerings from Dr. West's. Sign up for our newsletter to receive the freshest news and indulge in pleasures.
      </p>
    </div>
    <div class="emailBox">
      <div class="emailBoxs">
        <div class="emailError" v-if="emailError">
          Email addresses should follow the format user@domain.com.
        </div>
        <input class="emailInput" v-model="email" type="text" placeholder="Email Address">
      </div>
      <div class="discover" @click="goEmail">Suscribe</div>
    </div>

    <footerModal></footerModal>
  </div>
</template>

<script>
import navBar from '../components/navBar'
import footerModal from '../components/footer'
export default {
  name: "homePages",
  components: {navBar, footerModal},
  data() {
    return {
      email: '',
      emailError: false
    }
  },
  methods: {
    goPage(url) {
      this.$router.push(url)
    },
    goEmail() {
      this.emailError = false
      var emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
      if (!emailRegex.test(this.email)) {
        this.emailError = true
        return
      }
      this.$message({
        message: 'success',
        type: 'success'
      });
      this.email = ''
    }
  }
}
</script>

<style scoped>
.topImageBox{
  position: relative;
}

.pageImg{
  position: relative;
  top: -6vw;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  object-position: 50% 50%;
}
.contentBrunoTitle, .contentBrunoText{
  font-family: bodyFont;
  margin: 0;
}

.emailError{
  color: red;
}

.boutiqueInfo{
  flex: 0.8;
  display: flex;
  align-items: self-start;
  flex-direction: column;
}
.boutiqueTitle{
  font-family: bodyFont;
  margin-bottom: 3vw;
}
.discover{
  display: inline-block;
  color: #fff;
  padding: 1.5rem 3rem;
  border-radius: 0.3rem;
  background: #28266C;
  cursor: pointer;
}


.emailInput:hover{
  border: 1px solid #000;
}

</style>