<template>
  <div class="footer" :style="styleObj">
    <div class="contentBrunoTitle footerTitle">Dr. West`s <span class="tips">R</span></div>
    <div class="contentBrunoTitle city">PASSION BIOTECH SL.</div>
    <a href="mailto:info@bruno-court.com">info@dr-wests.com</a>
    <div class="Rights" style="margin-top: 1.5rem">Copyright © 2024 Dr. West`s - All Rights Reserved.</div>
  </div>
</template>

<script>
export default {
  name: "footerPage",
  props: {
    styleObj: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      windowHeight: 100
    }
  }
}
</script>

<style scoped>
.footer{
  padding: 4rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 6rem;
  background: #F1F1EF;
}
.footerTitle{
  position: relative;
  font-family: bodyFont;
}
.tips{
  position: absolute;
  top: 4px;
  right: -24px;
  border-radius: 50%;
  width:  1.2rem;
  font-size: 0.8rem;
  height: 1.2rem;
  line-height: 1.1rem;
  box-sizing: border-box;
  border: 2px solid #000;
  font-family: 'navTitle';
}
.city{
  font-size: 1rem;
}
a {
  color: #000;
  margin-top: 1rem;
}
</style>