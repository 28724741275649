<template>
  <div class="services">
    <navBar :navType="2"></navBar>
    <div class="pagesTitle">Explore our more products and services,Email us</div>
    <div class="content">
      <div class="item">
        <img class="itemImg" src="@/assets/shop1.png" alt="">
        <div class="itemInfo">
          <div class="itemTitle">Amino Acid Facial Foam Cleanser</div>
          <div class="itemText">
            Discover the gentle touch of Dr. West’s Amino Acid Facial Foam Cleanser, your ideal companion for a soothing cleansing experience. Formulated with a delicate balance of amino acids, this foam cleanser is designed to purify your skin without the harshness often found in traditional cleansers.
          </div>
        </div>
      </div>
      <div class="item">
        <img class="itemImg" src="@/assets/shop2.png" alt="" style="filter: blur(5px);">
        <div class="itemInfo">
          <div class="itemTitle">Coming soon</div>
          <div class="itemText">
            Anticipation builds as Dr. West’s latest products creation prepares to make its debut. Keep an eye out for the launch and seize the opportunity to be swept away. The wait is nearly over!
          </div>
        </div>
      </div>
      <div class="item">
        <img class="itemImg" src="@/assets/shop3.png" alt="" style="filter: blur(5px);">
        <div class="itemInfo">
          <div class="itemTitle">Coming soon</div>
          <div class="itemText">
            Anticipation builds as Dr. West’s latest products creation prepares to make its debut. Keep an eye out for the launch and seize the opportunity to be swept away. The wait is nearly over!
          </div>
        </div>
      </div>
    </div>
    <footerModal></footerModal>
  </div>
</template>

<script>
import navBar from '../components/navBar'
import footerModal from "@/components/footer";
export default {
  name: "servicesPages",
  components: {navBar, footerModal}
}
</script>

<style scoped>
.item{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.itemInfo{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
@media screen and (min-width: 768px), screen and (max-width: calc(2119px)) and (orientation: landscape) {
  .content{
    display: flex;
    /*align-items: center;*/
    justify-content: space-between;
    padding: 3vw;
    margin-top: 3vw;
  }
  .item{
    width: 26vw;
  }
  .itemImg{
    width: 100%;
  }
  .itemTitle{
    font-size: calc((1.2 - 1) * 1.2vw + 1rem);
    line-height: 1.2;
    font-family: bodyFont;
    margin-top: 2vw;
  }
  .itemText{
    font-size: calc(1rem);
    line-height: 1.5;
    font-family: 'navTitle';
    margin-top: 1vw;
    font-weight: 400;
    text-align: left;
  }
}

@media screen and (max-width: 768px) {
  .content{
    display: flex;
    /*align-items: center;*/
    flex-direction: column;
    justify-content: space-between;
    padding: 3vw;
    margin-top: 3vw;
  }
  .item{
    width: 94vw;
  }
  .itemImg{
    width: 100%;
    margin-top: 8vw;
  }
  .itemTitle{
    font-size: calc((4 - 1) * 1.2vw + 1rem);
    line-height: 1.2;
    font-family: bodyFont;
    margin-top: 8vw;
  }
  .itemText{
    font-size: calc(1rem);
    line-height: 1.5;
    font-family: 'navTitle';
    margin-top: 8vw;
    font-weight: 400;
    text-align: left;
  }
}
</style>